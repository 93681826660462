import { useEffect } from 'react';
import rawTilesSource from 'data/sources/rawTilesSource';
import { RSRP_POLY_LAYER_ID } from 'components/layers/RsrpPolyLayer';
import rasterTilesSource from 'data/sources/rasterTilesSource';
import { RSRP_LAYER_ID } from 'components/layers/RsrpLayer';
import { useDispatch, useSelector } from 'react-redux';
import {
  addLayer,
  removeLayer,
  addSource,
  removeSource,
  updateLayer,
} from '@carto/react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { AggregationTypes } from '@carto/react-core';
import { CategoryWidget, FormulaWidget, HistogramWidget } from '@carto/react-widgets';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function Rsrp() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const zoom = useSelector((state) => state.carto.viewState.zoom);

  useEffect(() => {
    dispatch(
      updateLayer({
        id: RSRP_POLY_LAYER_ID,
        layerAttributes: { visible: false },
      })
    );
  }, []);

  useEffect(() => {
    dispatch(addSource(rasterTilesSource));

    dispatch(
      addLayer({
        id: RSRP_LAYER_ID,
        source: rasterTilesSource.id,
      })
    );

    return () => {
      dispatch(removeLayer(RSRP_LAYER_ID));
      dispatch(removeSource(rasterTilesSource.id));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(addSource(rawTilesSource));

    dispatch(
      addLayer({
        id: RSRP_POLY_LAYER_ID,
        source: rawTilesSource.id,
      })
    );

    return () => {
      dispatch(removeLayer(RSRP_POLY_LAYER_ID));
      dispatch(removeSource(rawTilesSource.id));
    };
  }, [dispatch]);

  useEffect(() => {
    if (zoom > 13) {
      dispatch(
        updateLayer({
          id: RSRP_LAYER_ID,
          layerAttributes: { visible: false },
        })
      );
      dispatch(
        updateLayer({
          id: RSRP_POLY_LAYER_ID,
          layerAttributes: { visible: true },
        })
      );
    } else if (zoom <= 13) {
      dispatch(
        updateLayer({
          id: RSRP_LAYER_ID,
          layerAttributes: { visible: true },
        })
      );
      dispatch(
        updateLayer({
          id: RSRP_POLY_LAYER_ID,
          layerAttributes: { visible: false },
        })
      );
    }
  }, [dispatch, zoom]);

  // Auto import useEffect

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item>
        {/* {zoom} */}
        {zoom <= 13 ? (
          <HistogramWidget
            id='aggTotalHistogramCount'
            title='Band Strength (Agg. Min.)'
            dataSource={rasterTilesSource.id}
            operation={AggregationTypes.COUNT}
            column='min_band'
            ticks={[-120, -115, -110, -105, -100, -95, -90, -85, -80]}
            viewportFilter
          />
        ) : (
          <HistogramWidget
            id='aggTotalHistogramCountRaw'
            title='Band Strength'
            dataSource={rawTilesSource.id}
            operation={AggregationTypes.COUNT}
            column='band'
            ticks={[-120, -115, -110, -105, -100, -95, -90, -85, -80]}
            viewportFilter
          />
        )}
      </Grid>
    </Grid>
  );
}
