(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-ui"), require("@carto/react-core"), require("@carto/react-api"), require("@carto/react-workers"), require("@deck.gl/carto/typed"), require("@carto/react-api/"), require("@carto/react-redux/"), require("@carto/react-core/"), require("@carto/react-ui/"), require("@material-ui/core"), require("@material-ui/core/styles"), require("@nebula.gl/edit-modes"), require("@nebula.gl/layers"), require("@carto/react-basemaps/"), require("@deck.gl/layers/typed"));
	else if(typeof define === 'function' && define.amd)
		define(["react", "react-redux", "@carto/react-redux", "@carto/react-ui", "@carto/react-core", "@carto/react-api", "@carto/react-workers", "@deck.gl/carto/typed", "@carto/react-api/", "@carto/react-redux/", "@carto/react-core/", "@carto/react-ui/", "@material-ui/core", "@material-ui/core/styles", "@nebula.gl/edit-modes", "@nebula.gl/layers", "@carto/react-basemaps/", "@deck.gl/layers/typed"], factory);
	else if(typeof exports === 'object')
		exports["cartoReactWidgets"] = factory(require("react"), require("react-redux"), require("@carto/react-redux"), require("@carto/react-ui"), require("@carto/react-core"), require("@carto/react-api"), require("@carto/react-workers"), require("@deck.gl/carto/typed"), require("@carto/react-api/"), require("@carto/react-redux/"), require("@carto/react-core/"), require("@carto/react-ui/"), require("@material-ui/core"), require("@material-ui/core/styles"), require("@nebula.gl/edit-modes"), require("@nebula.gl/layers"), require("@carto/react-basemaps/"), require("@deck.gl/layers/typed"));
	else
		root["cartoReactWidgets"] = factory(root["react"], root["react-redux"], root["@carto/react-redux"], root["@carto/react-ui"], root["@carto/react-core"], root["@carto/react-api"], root["@carto/react-workers"], root["@deck.gl/carto/typed"], root["@carto/react-api/"], root["@carto/react-redux/"], root["@carto/react-core/"], root["@carto/react-ui/"], root["@material-ui/core"], root["@material-ui/core/styles"], root["@nebula.gl/edit-modes"], root["@nebula.gl/layers"], root["@carto/react-basemaps/"], root["@deck.gl/layers/typed"]);
})(self, function(__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__756__, __WEBPACK_EXTERNAL_MODULE__347__, __WEBPACK_EXTERNAL_MODULE__214__, __WEBPACK_EXTERNAL_MODULE__485__, __WEBPACK_EXTERNAL_MODULE__178__, __WEBPACK_EXTERNAL_MODULE__116__, __WEBPACK_EXTERNAL_MODULE__385__, __WEBPACK_EXTERNAL_MODULE__591__, __WEBPACK_EXTERNAL_MODULE__987__, __WEBPACK_EXTERNAL_MODULE__38__, __WEBPACK_EXTERNAL_MODULE__815__, __WEBPACK_EXTERNAL_MODULE__670__, __WEBPACK_EXTERNAL_MODULE__793__, __WEBPACK_EXTERNAL_MODULE__59__, __WEBPACK_EXTERNAL_MODULE__102__, __WEBPACK_EXTERNAL_MODULE__306__, __WEBPACK_EXTERNAL_MODULE__782__) {
return 