import { useEffect } from 'react';
import mhzPopsSource from 'data/sources/mhzPopsSource';

import { MHZ_POPS_LAYER_ID } from 'components/layers/MhzPopsLayer';

import { useDispatch } from 'react-redux';
import { addLayer, removeLayer, addSource, removeSource } from '@carto/react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
}));

export default function MhzPops() {
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(addSource(mhzPopsSource));

    dispatch(
      addLayer({
        id: MHZ_POPS_LAYER_ID,
        source: mhzPopsSource.id,
      })
    );

    return () => {
      dispatch(removeLayer(MHZ_POPS_LAYER_ID));
      dispatch(removeSource(mhzPopsSource.id));
    };
  }, [dispatch]);

  // Auto import useEffect

  return (
    <Grid container direction='column' className={classes.root}>
      <Grid item>Hello World</Grid>
    </Grid>
  );
}
