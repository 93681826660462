import GeocoderLayer from './GeocoderLayer';
import RsrpLayer from './RsrpLayer';
import RsrpPolyLayer from './RsrpPolyLayer';
import MhzPopsLayer from './MhzPopsLayer';
// Auto import

export const getLayers = () => {
  return [
    RsrpLayer(),
    RsrpPolyLayer(),
    // Auto import layers
  ];
};
