import { VOYAGER } from '@carto/react-basemaps';

export const initialState = {
  viewState: {
    latitude: 53.555467,
    longitude: -113.772899,
    zoom: 12,
    pitch: 0,
    bearing: 0,
    dragRotate: false,
  },
  basemap: VOYAGER,
  credentials: {
    apiVersion: 'v2',
    username: 'se-team-admin',
    apiKey: '40d11a3f0b71832315321afddcf4c6e1a0ef6e23',
    serverUrlTemplate: 'https://{user}.carto.com',
  },
  googleApiKey: '', // only required when using a Google Basemap
};

export const oauthInitialState = {
  oauthApp: {
    clientId: 'AlsIDKP6xAHe',
    scopes: [
      'user:profile', // to load avatar photo
      'dataservices:geocoding', // to use geocoding through Data Services API
      'dataservices:isolines', // to launch isochrones or isodistances through Data Services API
    ],
    authorizeEndPoint: 'https://carto.com/oauth2/authorize', // only valid if keeping https://localhost:3000/oauthCallback
  },
  token: null,
  userInfo: null,
};
