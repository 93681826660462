import { useSelector } from 'react-redux';
import { CartoLayer, MAP_TYPES } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from 'utils/htmlForFeature';
import { scaleThreshold } from 'd3-scale';
import { useEffect, useState } from 'react';
import useLayer from 'config/useLayer';

export const RSRP_POLY_LAYER_ID = 'rsrpPolyLayer';

const temps = [
  '#009392',
  '#39b185',
  '#9ccb86',
  '#e9e29c',
  '#eeb479',
  '#e88471',
  '#cf597e',
];

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  console.log([
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
    200,
  ]);
  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), 200];
}

const COLOR_SCALE = scaleThreshold()
  .domain([-125, -115, -105, -95, -85, -75, -65])
  .range([
    hexToRgb(temps[0]),
    hexToRgb(temps[1]),
    hexToRgb(temps[2]),
    hexToRgb(temps[3]),
    hexToRgb(temps[4]),
    hexToRgb(temps[5]),
    hexToRgb(temps[6]),
  ]);

function RsrpPolyLayer() {
  const { rsrpPolyLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, rsrpPolyLayer?.source));
  const cartoFilterProps = useCartoLayerProps({ source });
  const layerData = useLayer(RSRP_POLY_LAYER_ID);
  const zoom = useSelector((state) => Math.floor(state.carto.viewState.zoom));
  const visible = layerData?.visible;
  const [vis, setVis] = useState(visible);

  useEffect(() => {
    if (zoom > 13) {
      setVis(true);
    } else {
      setVis(false);
    }
  }, [zoom]);

  if (rsrpPolyLayer && source) {
    return new CartoLayer({
      ...cartoFilterProps,
      id: RSRP_POLY_LAYER_ID,
      type: MAP_TYPES.TILESET,
      visible: vis,
      data: source.data,
      credentials: source.credentials,
      getFillColor: (d) => COLOR_SCALE(d.properties.band),
      pointRadiusMinPixels: 2,
      pickable: true,
      colorFormat: 'RGBA',
      filled: true,
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: htmlForFeature(info.object),
            style: {},
          };
        }
      },
    });
  }
}

export default RsrpPolyLayer;
