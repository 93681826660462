const MHZ_POPS_SOURCE_ID = 'mhzPopsSource';

const source = {
  id: MHZ_POPS_SOURCE_ID,
  data: `
  select cartodb_id, st_centroid(the_geom_webmercator) as the_geom_webmercator, st_centroid(the_geom) as the_geom from mhz_pop_850
  `,
  type: 'sql',
};

export default source;
