import { useSelector } from 'react-redux';
import { CartoLayer, MAP_TYPES } from '@deck.gl/carto';
import { selectSourceById } from '@carto/react-redux';
import { useCartoLayerProps } from '@carto/react-api';
import htmlForFeature from 'utils/htmlForFeature';
import { scaleThreshold } from 'd3-scale';
import { useEffect, useState } from 'react';
import useLayer from 'config/useLayer';

export const RSRP_LAYER_ID = 'rsrpLayer';

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  console.log([
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
    200,
  ]);
  return [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16), 200];
}
const temps = [
  '#009392',
  '#39b185',
  '#9ccb86',
  '#e9e29c',
  '#eeb479',
  '#e88471',
  '#cf597e',
];

// const hexToRgb = (hex) => {
//   var bigint = parseInt(hex, 16);
//   var r = (bigint >> 16) & 255;
//   var g = (bigint >> 8) & 255;
//   var b = bigint & 255;
//   console.log([r, b, g, 200])
//   return [r, b, g, 200]
// }

const COLOR_SCALE = scaleThreshold()
  .domain([0, 10, 100, 1000, 10000, 100000, 1000000])
  .range([
    hexToRgb(temps[0]),
    hexToRgb(temps[1]),
    hexToRgb(temps[2]),
    hexToRgb(temps[3]),
    hexToRgb(temps[4]),
    hexToRgb(temps[5]),
    hexToRgb(temps[6]),
  ]);

function RsrpLayer() {
  const layerData = useLayer(RSRP_LAYER_ID);
  const zoom = useSelector((state) => Math.floor(state.carto.viewState.zoom));
  const visible = layerData?.visible;
  const [vis, setVis] = useState(visible);
  const { rsrpLayer } = useSelector((state) => state.carto.layers);
  const source = useSelector((state) => selectSourceById(state, rsrpLayer?.source));
  const cartoFilterProps = useCartoLayerProps({ source });

  useEffect(() => {
    if (zoom <= 13) {
      setVis(true);
    } else {
      setVis(false);
    }
  }, [zoom]);

  if (rsrpLayer && source) {
    return new CartoLayer({
      ...cartoFilterProps,
      id: RSRP_LAYER_ID,
      type: MAP_TYPES.TILESET,
      visible: vis,
      data: source.data,
      colorFormat: 'RGBA',
      filled: true,
      credentials: source.credentials,
      getFillColor: (d) => COLOR_SCALE(d.properties.aggregated_total),
      pointRadiusMinPixels: 2,
      pickable: true,
      onHover: (info) => {
        if (info?.object) {
          info.object = {
            html: htmlForFeature(info.object),
            style: {},
          };
        }
      },
    });
  }
}

export default RsrpLayer;
